/* eslint-disable no-console */
import { get, delay, defer } from 'lodash';
import { safeLocalStorage as ls } from '../lib';
import firebase from 'firebase';

const lsKeys = {
  nuageDxUser: 'nuageDxUser',
  nuageDxPath: 'nuageDxPath',
};

const getUser = () => ls.getItem(lsKeys.nuageDxUser) || {};
const getLastPath = () => ls.getItem(lsKeys.nuageDxPath) || '';

const nuageUser = {
  getUser,
  getAccessToken: () => get(getUser(), 'stsTokenManager.accessToken'),
  getHeaders: () => {
    const user = getUser();

    return {
      firebase_auth_token: get(user, 'stsTokenManager.accessToken'),
      firebase_auth_uid: user.uid,
    };
  },
  checkIfValidUser: ({ pathname }) => {
    console.log(`checkIfValidUser()`, {
      pathname,
      lastPath: getLastPath(),
    });

    if (!nuageUser.getAccessToken() && pathname !== '/') {
      // eslint-disable-next-line no-console
      console.log(
        'checkIfValidUser::User Not Logged in on alternative path, send to login...'
      );
      ls.setItem(lsKeys.nuageDxPath, pathname);
      defer(() => console.log('checkIfValidUser::setLastPath', getLastPath()));
      delay(() => (window.location = '/'), 100);
    }
  },
  getLastPath,
  setUser: user => {
    ls.setItem(lsKeys.nuageDxUser, user);
  },
  logout: (refresh = true) => {
    console.log('nuageUser.logout::Attempt logout from firebase');

    // Log them out from firebase
    firebase
      .auth()
      .signOut()
      .then(() => console.log('nuageUser.logout::firebase good'))
      .catch(e => console.error('nuageUser.logout::firebase failure', e));

    // Clear their local session.
    ls.setItem(lsKeys.nuageDxUser, '');
    ls.setItem('state', ''); // Clear our redux too.
    console.log('nuageUser.logout::Local session cleared');

    // If desired, finally send them back to the homepage to login again.
    refresh && window.location.reload();
  },
};

export default nuageUser;
