import { noop, isObject } from 'lodash';
import getWindow from './getWindow';

const localStorage = getWindow('localStorage');
let ls;
try {
  ls = localStorage;
} catch (e) {
  // Ignore
}

if (!ls) {
  ls = { getItem: noop, setItem: noop };
}

const safeLocalStorage = {
  setItem: (key, value) => {
    // Save to local storage
    ls.setItem(key, isObject(value) ? JSON.stringify(value) : value);

    return value;
  },
  getItem: key => {
    // Get from local storage by key
    const item = ls.getItem(key) || '';
    // Parse stored json or if none return initialValue
    let value = item;

    // Attempt to parse the object from the string.
    try {
      value = JSON.parse(item);
    } catch (e) {
      // ignore
    }

    return value;
  },
};

export default safeLocalStorage;
