/* eslint-disable no-console */
import React, { Component } from 'react';
import Zendesk from 'react-zendesk';
// Nuage API key
const ZENDESK_KEY = '42475b0d-4590-4ec3-9c1f-6ee684fd4319';

// Take contact form as an example
// Let's customise our contact form appearance, launcher and add prefill content
const setting = {
  // color: {
  //   theme: '#000',
  // },
  // launcher: {
  //   chatLabel: {
  //     'en-US': 'Need Help',
  //   },
  // },
  // contactForm: {
  //   fields: [
  //     { id: 'description', prefill: { '*': 'My pre-filled description' } },
  //   ],
  // },
  webWidget: {
    helpCenter: {
      filter: {
        section: '360012063033-Help',
      },
    },
  },
};

class NuageZendesk extends Component {
  render() {
    return (
      <Zendesk
        zendeskKey={ZENDESK_KEY}
        {...setting}
        onLoaded={() => console.log('is loaded')}
      />
    );
  }
}

export default NuageZendesk;
