import React from 'react';
// import { Icon } from '@ohif/ui';
// import { Link } from 'react-router-dom';
import './OHIFLogo.css';
import { site } from '../../../../../../config/labels';

function OHIFLogo() {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="header-brand"
      href="http://ohif.org"
    >
      {/*<Icon name="ohif-logo" className="header-logo-image" />*/}
      {/* Logo text would fit smaller displays at two lines:
       *
       * Open Health
       * Imaging Foundation
       *
       * Or as `OHIF` on really small displays
       */}
      {/*<Icon name="ohif-text-logo" className="header-logo-text" />*/}
      <img src="/assets/nuagedex-logo.png" alt="Nuage Diagnostics" />
      <h1>{site.headerTitle}</h1>
    </a>
  );
}

export default OHIFLogo;
