import { apiErrors } from '../../../../../config/labels';
import { nuageUser } from '@ohif/viewer/src/naugeDx/state';

/**
 * Handle API error fallback, to inform users of their account related issues accessing files.
 * @param e
 */
export default function nuageDxError(e) {
  let message = apiErrors.serverError;
  // Determine the time out to force users to login again.
  let logoutTimer = apiErrors.logoutOnFailureTimeout || 10 * 1000;
  // The potential notice for when asking users to login again.
  let logoutOnFailureNotice = '';

  switch (e.status) {
    case 403:
      message = apiErrors.invalidAccessError;
      break;
    case 404:
      message = apiErrors.studyNotFound;
      logoutTimer = 0;
      break;
  }

  // If the logoutTimer wasn't cleared for specific error statuses, serve the notice.
  if (logoutTimer > 0) {
    logoutOnFailureNotice = apiErrors.logoutNotice.replace(
      '{logoutOnFailureTimeout}',
      String(logoutTimer / 1000)
    );
  }

  // eslint-disable-next-line no-console
  console.log('study error', {
    message,
    logoutOnFailureNotice,
    logoutTimer,
    e,
  });

  // Add the toast message to the page once the page is loaded.
  setTimeout(() => {
    const toast = `
        <div class="Toaster__manager-bottom-right" style="position: fixed; z-index: 5500; pointer-events: none; bottom: 1rem; right: 1rem;">
          <div class="Toaster__message" style="opacity: 1; height: 114px; display: flex; flex-direction: column; align-items: flex-end;">
            <div class="Toaster__message-wrapper" style="transform: translateY(0%) scale(1); pointer-events: auto;">
              <div data-reach-alert="true">
                <div class="sc-AxjAm sc-pscky sc-qPVvu fSaTqW">
                  <div class="sc-qYsMX dsJsA" style="
                      position: relative;
                      max-width: 25rem;
                      padding: 0.9375rem;
                      font-weight: 400;
                      font-size: 0.875rem;
                      line-height: 1.3;
                      letter-spacing: -0.3px;
                      border-width: 1px;
                      border-style: solid;
                      border-radius: 0px;
                      background-color: rgb(255, 231, 231);
                      border-color: rgb(206, 89, 71);
                  ">
                    <div class="sc-AxjAm sc-pscky sc-pCOPB khWhfc" style=" 
                      display: flex;
                      -webkit-box-align: center;
                      align-items: center;
                      color: rgb(175, 70, 54);
                      padding-bottom: 0.75rem;
                      padding-right: 1.875rem;
                      font-family: welcome-font, sans-serif;
                      font-weight: 600;
                      font-size: 1rem;
                      line-height: 1.2;
                      letter-spacing: -0.5px;
                      ">
                      <svg style="width: 1rem; height: 1rem; margin-right: .5rem;" alt="Alert" role="img" viewBox="0 0 100 100" class="sc-qQYBZ lgOFmV"><path d="M49.814 6.003a6.474 6.474 0 0 1 5.728 3.067l41.48 66.67a6.395 6.395 0 0 1 .32 6.225 6.482 6.482 0 0 1-5.123 3.604L9.256 94.957A6.502 6.502 0 0 1 3.12 92.15a6.41 6.41 0 0 1-.33-6.703L44.27 9.39c1.12-2.054 3.193-3.319 5.544-3.386zm.197 4.678h-.063a1.772 1.772 0 0 0-1.532.938L6.934 87.677c-.47.86-.093 1.582.09 1.853.185.27.728.89 1.697.777l82.964-9.39a1.78 1.78 0 0 0 1.418-.996c.13-.266.38-.967-.088-1.722L51.533 11.53c-.493-.79-1.283-.857-1.585-.848zm1.871 59.928c.187.174.349.388.498.602.147.213.27.454.377.695.093.24.176.495.23.749.038.254.067.521.067.789 0 .254-.029.522-.068.776a4.963 4.963 0 0 1-.229.749 4.44 4.44 0 0 1-.377.695 3.84 3.84 0 0 1-.498.615 4.05 4.05 0 0 1-5.71 0 4.047 4.047 0 0 1-1.185-2.835 4 4 0 0 1 1.185-2.835c1.496-1.498 4.2-1.498 5.71 0zm-2.859-37.186c2.164 0 3.93 1.689 4.035 3.812l.004.2v20.272c0 2.215-1.808 4.012-4.039 4.012-2.163 0-3.93-1.69-4.034-3.812l-.005-.2V37.435c0-2.216 1.808-4.012 4.04-4.012z" fill="currentColor" fill-rule="evenodd"></path></svg>
                      ${apiErrors.modalTitle}
                    </div>
                    ${message} ${apiErrors.loginAgain} ${logoutOnFailureNotice}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        `;

    const elToast = document.createElement('div');
    elToast.innerHTML = toast;
    // Ensure the logout link performs as desired.
    try {
      elToast.querySelector('.ApiErrorLogout').addEventListener('click', () => {
        nuageUser.logout();
      });
    } catch (e) {
      // Ignore if not present.
    }
    // Add the toast to the page.
    document.body.appendChild(elToast);

    // If enabled, auto logout users after the logoutTimer setting.
    logoutTimer && setTimeout(nuageUser.logout, logoutTimer);

    // eslint-disable-next-line no-console
    console.log('study error toast', elToast);
  }, 1000);
}
