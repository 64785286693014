const site = {
  headerTitle: "Viewer",
};

const login = {
  modalTitle: "Login",
  logoutButton: "Logout",
  user: "User",
  modalDetails:
    'Please re-enter your email and password from <a style="color: #d25f59;" href="https://nuagedx.com">nuagedx.com</a> to access the viewer.',
  invalidLogin:
    "There was an error with validating your account from nuagedx.com.<br/> Please check and try logging in again.",
  generalLoginError:
    "There was an error with your email and or password.<br/> Please check and try logging in again.",
};

const apiErrors = {
  modalTitle: "An Error Has Occurred",
  serverError:
    'You may not have access to this study, please check your link and try again on <a style="color: #d25f59;" href="https://nuagedx.com">nuagedx.com</a>.',
  invalidAccessError:
    'You are not authorized to view this study, please check your available studies on <a style="color: #d25f59;" href="https://nuagedx.com">nuagedx.com</a>.',
  studyNotFound:
    'The study you\'re trying to view doesn\'t seem to exist, please check your available studies on <a style="color: #d25f59;" href="https://nuagedx.com">nuagedx.com</a>.',
  loginAgain:
    "Or, try to <a href='#' style='color: #d25f59;' class='ApiErrorLogout'>Login again here</a>.",
  logoutNotice:
    "You will automatically be logged out in {logoutOnFailureTimeout} seconds.",
  logoutOnFailureTimeout: 10 * 1000, // in ms, 10s by default
};

module.exports = {
  apiErrors,
  login,
  site,
};
