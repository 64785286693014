const firebaseConfig = {
  apiKey: "AIzaSyCaMW--IxXHWpzH9YsM_gxMOL9tFldan5o",
  authDomain: "nuagedx-dev.firebaseapp.com",
  databaseURL: "https://nuagedx-dev.firebaseio.com",
  projectId: "nuagedx-dev",
  storageBucket: "nuagedx-dev.appspot.com",
  messagingSenderId: "409596160399",
  appId: "1:409596160399:web:509f0f7d969fee7ce5c3fa"
};

module.exports = firebaseConfig;
